<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { DEFAULT_MANAGED_LOGO, SLIDE_ANIM_MS } from '$src/constants.js'
    import { dedupe, preventDefault } from '$utils/common'
    import { putName } from '$utils/api.js'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import Dropdown from '$lib/Dropdown.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        handleDropdown = () => {},
    } = $props()

    let value = $state('')

    let addFirstNameAjax = $state(false)
    async function addFirstName() {
        addFirstNameAjax = true
        const { given_names } = await putName('given_name', value)
        if (given_names) {
            global.data.release.given_names = given_names
            selected = global.data.release.given_names[0]
            expanded = false
            value = ''
        }
        addFirstNameAjax = false
    }
</script>

<div data-test="first-name-container" class="space-y-1">
    <label for="first-name" class="text-sm font-medium">
        {$_('First Name (given name)')}
    </label>

    {#if global.data?.release?.managed?.given_name}
        {@const meta = global.data?.release?.managed?._meta}
        <div
            class="bg-charcoal block flex h-12 items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
        >
            <span class="managed-rounded-square-sm">
                <img
                    src={meta.logo || DEFAULT_MANAGED_LOGO}
                    alt={meta.domain || 'logo'}
                    class="w-4.5 max-h-[18px] object-contain"
                />
            </span>
            <span class="ml-4">{global.data?.release?.managed?.given_name}</span
            >
        </div>
    {:else}
        <Dropdown
            hasBackground={false}
            dataTest="first-name-dropdown-btn"
            id="first-name-dropdown"
            ariaLabel={selected}
            {expanded}
            onclick={() => handleDropdown('firstName')}
        >
            <div
                data-test="selected-first-name"
                class="flex h-12 w-full items-center justify-between px-2"
                aria-hidden="true"
            >
                {selected}
            </div>

            {#if expanded}
                {@const givenNames = Array.isArray(
                    global.data?.release?.given_names,
                )
                    ? dedupe(global.data.release.given_names).filter(
                          (i) => i !== selected,
                      )
                    : []}
                <div
                    class="px-indent flex w-full flex-col rounded-md text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul
                        class="mt-0.5 {givenNames.length
                            ? 'border-y'
                            : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                    >
                        {#each givenNames as name, index (name)}
                            <li>
                                <button
                                    data-test={`first-name-${index}`}
                                    onclick={() => {
                                        selected = name
                                        expanded = false
                                    }}
                                    class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                                >
                                    {name}
                                </button>
                            </li>
                        {/each}
                    </ul>

                    <form onsubmit={preventDefault(addFirstName)} class="my-2">
                        <input
                            type="text"
                            name="given-name"
                            id="given-name"
                            bind:value
                            autocomplete="given-name"
                            required
                            placeholder={$_('enter your first name')}
                            class="h-12 w-full px-[16px] sm:px-[18px]"
                        />
                        <button
                            data-test="add-first-name-btn"
                            type="submit"
                            disabled={!value.length || addFirstNameAjax}
                            class="btn-background mt-2 flex w-full flex-shrink-0 items-center justify-center disabled:opacity-50"
                        >
                            {#if addFirstNameAjax}
                                <SpinnerIcon css="h-5 w-5 text-white" />
                            {:else if global.data?.release?.given_names?.length}
                                <span>{$_('Add another first name')}</span>
                            {:else}
                                <span>{$_('Add first name')}</span>
                            {/if}
                        </button>
                    </form>
                </div>
            {/if}
        </Dropdown>
    {/if}
</div>

<style>
    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
