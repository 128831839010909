<script>
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onDestroy, onMount } from 'svelte'
    import { getConsent, putName } from '$utils/api.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import { IS_PROD } from '$src/constants.js'
    import { preventDefault } from '$utils/common.js'

    let value = $state('')
    let addLastNameAjax = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let evtSource = $state(null)
    let authCancelledAtInitClient = $state(false)

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we already got a family name
        if (global.data.release?.family_names?.length) return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/last-name' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function addLastName() {
        addLastNameAjax = true
        const { family_names } = await putName('family_name', value, false)
        global.data.release.family_names = family_names
        navigateTo('/')
    }
</script>

<AuthorizeLayout
    heading={$_('Requires your last name (family name)')}
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <form class="mx-auto mt-2 max-w-md" onsubmit={preventDefault(addLastName)}>
        <div class="relative">
            <!-- svelte-ignore a11y_autofocus -->
            <input
                bind:value
                type="text"
                id="family-name"
                name="family-name"
                class="h-12 w-full px-4"
                required
                autofocus
                autocomplete="family-name"
                placeholder={$_('enter your last name')}
            />
        </div>

        <div class="mt-2 flex items-center justify-between">
            <button
                data-test="continue-btn"
                type="submit"
                disabled={value.length <= 2 || addLastNameAjax}
                class="btn-background w-full transition disabled:cursor-not-allowed disabled:opacity-50"
            >
                {#if addLastNameAjax}
                    <SpinnerIcon css="h-5 w-5 mx-auto text-white" />
                {:else}
                    {$_('Continue')}
                {/if}
            </button>
        </div>
    </form>
</AuthorizeLayout>
