<script>
    import { _ } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { DEFAULT_MANAGED_LOGO } from '$src/constants.js'
</script>

<div data-test="org-info-container" class="space-y-1">
    <label for="org-info" class="text-sm font-medium">
        {$_('Organization')}
    </label>
    <div
        class="bg-charcoal block flex h-12 items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
    >
        <span class="managed-rounded-square-sm">
            <img
                src={global.data?.release?.managed?._meta?.logo ||
                    DEFAULT_MANAGED_LOGO}
                alt={global.data?.release?.managed?._meta?.domain || 'logo'}
                class="w-4.5 max-h-[18px] object-contain"
            />
        </span>
        <span class="ml-4">{global.data?.release?.managed?._meta.domain}</span>
    </div>
</div>
