<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import logins from '$svr/providers/logins.json'
    import { global } from '$src/state.svelte.js'
    import { dedupe } from '$utils/common'
    import { getConsent } from '$utils/api.js'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        handleDropdown = () => {},
        continueWithProvider = () => {},
    } = $props()

    let continueWithEmailForExisting = $state(false)

    async function verifyEmailSuccess() {
        const res = await getConsent()
        if (!res.merge) {
            global.data.release.emails = res.release.emails
            global.data.release.accounts = res.release.accounts
            if (global.data.release.existing_usernames) {
                global.data.release.existing_usernames =
                    res.release.existing_usernames
            }
            selected = global.data.release.emails[0]
        }
        expanded = false
        continueWithEmailForExisting = false
    }
</script>

<div
    data-test="existing-username-container"
    id="existing-username"
    class="space-y-1"
>
    <label for="existing-username" class="text-sm font-medium">
        {$_('Username')} @verified.coop
    </label>
    <div id="existing-username" class="box-shadow space-y-2 rounded-md p-2">
        {#if selected && global.data?.release?.existing_usernames}
            {@const existingUsernames = dedupe([
                ...Object.keys(global.data.release.existing_usernames),
            ])}
            <div
                data-test="existing-username-container"
                class="relative flex items-center"
            >
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each existingUsernames as existing_username, index (existing_username)}
                            <li
                                data-test="existing-username-{index}"
                                class="flex w-full items-center"
                            >
                                <input
                                    id="existing-username-{existing_username}"
                                    name="existing-username"
                                    type="radio"
                                    bind:group={selected}
                                    value={existing_username}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                />
                                <label
                                    for="existing-username-{existing_username}"
                                    class="ml-2.5 inline-flex w-full items-center truncate"
                                >
                                    <span class="truncate">
                                        @{existing_username}@verified.coop
                                    </span>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <div class="relative">
                <span
                    data-test="link-more-username-cta"
                    class="mb-1 block italic opacity-80"
                >
                    Link another account to verify a different username
                </span>
                <Dropdown
                    hasBackground={false}
                    dataTest="add-provider-btn"
                    ariaLabel={$_('Link another account')}
                    {expanded}
                    onclick={() => handleDropdown('existingUsernameAccounts')}
                >
                    <div
                        class="flex h-12 items-center px-4 text-left"
                        aria-hidden="true"
                    >
                        {$_('Link another account')}
                    </div>

                    {#if expanded}
                        <ul
                            class="space-y-2 p-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            {#each logins.filter((i) => i.claims.existing_username) as provider}
                                <LoginProvider
                                    {provider}
                                    prefix={Array.isArray(
                                        global.data?.accounts,
                                    ) &&
                                    global.data?.accounts.includes(
                                        provider.slug,
                                    )
                                        ? 'Link another'
                                        : 'Link'}
                                    onclick={(e) =>
                                        continueWithProvider(
                                            provider.slug,
                                            null,
                                            e,
                                        )}
                                />
                                {#if provider.slug === 'google'}
                                    <Dropdown
                                        id="email-container"
                                        expanded={continueWithEmailForExisting}
                                        ariaLabel={$_('Add email')}
                                        dataTest="add-email-btn"
                                        onclick={() => {
                                            continueWithEmailForExisting =
                                                !continueWithEmailForExisting
                                        }}
                                    >
                                        <div
                                            class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                                        >
                                            <MailIcon />
                                            <span
                                                class="block text-left"
                                                aria-hidden="true"
                                            >
                                                {$_('Add email')}
                                            </span>
                                        </div>
                                        {#if continueWithEmailForExisting}
                                            <div
                                                class="px-2 pb-2 pt-1"
                                                transition:slide={{
                                                    duration: SLIDE_ANIM_MS,
                                                }}
                                            >
                                                <VerifyEmail
                                                    success={verifyEmailSuccess}
                                                />
                                            </div>
                                        {/if}
                                    </Dropdown>
                                {/if}
                            {/each}
                        </ul>
                    {/if}
                </Dropdown>
            </div>
        {/if}
    </div>
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }
</style>
