<script>
    import { onMount } from 'svelte'
    import { global } from '$src/state.svelte.js'
    import { deleteCookies } from '$utils/api.js'
    import FullPageModal from '$src/lib/modal/FullPageModal.svelte'

    let params = $state(new URLSearchParams())
    let host = $state(null)

    const reload = () =>
        (window.location.href = window.location.origin + '/authorize')

    onMount(() => {
        try {
            params = new URLSearchParams(sessionStorage.authorize_query_params)
            host = new URL(params.get('redirect_uri')).hostname
        } catch (err) {
            console.error(err)
        }
        global.spinner = false
    })

    async function startOver() {
        global.serverErrorModal = false
        global.clientErrorModal = false
        global.spinner = true
        try {
            await deleteCookies()
        } catch (err) {
            console.error(err)
        }
        window.location.href = params.get('redirect_uri')
    }
</script>

<FullPageModal
    dataTest="unrecoverable-error-modal"
    modalCss="sm:max-w-sm sm:!-mt-80"
>
    <div class="mx-auto w-full text-center">
        <h1 class="text-lg font-medium text-red-500">
            {#if global.serverErrorModal}
                The server got confused
            {:else}
                Something went wrong
            {/if}
        </h1>
        <p class="mt-2 text-sm opacity-80">
            We've been notified and are looking into it.
        </p>
        {#if global.serverErrorModal}
            {#if host}
                <button
                    onclick={startOver}
                    class="btn-background mt-5 inline-flex !h-auto w-full items-center justify-center space-x-2 py-2"
                >
                    Return to {host}<br />and start over
                </button>
            {:else}
                <button
                    onclick={() =>
                        (window.location.href = window.location.origin + '/')}
                    class="btn-background mt-5 inline-flex w-full items-center justify-center space-x-2"
                >
                    <span>Return to Wallet Profile</span>
                </button>
            {/if}
        {:else}
            <button
                onclick={reload}
                class="btn-background mt-5 inline-flex w-full items-center justify-center space-x-2"
            >
                <span>Let's try that again</span>
            </button>
        {/if}
    </div>
</FullPageModal>
