<script>
    import { onMount } from 'svelte'
    import { _ } from 'svelte-i18n'
    import {
        FALLBACK_APP_ICON_LIGHT,
        FALLBACK_APP_ICON_DARK,
    } from '$src/constants'

    let { app = {}, isDeviceCodeFlow = false } = $props()

    let redirect_uri = $state(null)

    onMount(() => {
        if (!isDeviceCodeFlow) {
            try {
                redirect_uri = new URL(app.redirect_uri)
            } catch (err) {
                console.error('Invalid URL', err)
            }
        }
    })
</script>

<section
    class="max-w-col stack:mt-0 stack:w-full unstack:w-1/2 unstack:px-indent stack:px-0 stack:pb-[5vh] unstack:mr-auto unstack:ml-0 stack:mx-auto mx-auto mt-1 w-full px-0 pb-4 [@media(min-height:1216px)]:!pb-16"
>
    <div
        class="app-info-logo-container stack:justify-center mx-auto flex items-center"
    >
        {#if (!app.image_uri || app.image_uri == 'undefined') && (!app.dark_image_uri || app.dark_image_uri == 'undefined')}
            <picture>
                <source
                    srcset={FALLBACK_APP_ICON_LIGHT}
                    media="(prefers-color-scheme: dark)"
                />
                <img
                    src={FALLBACK_APP_ICON_DARK}
                    alt={app.name}
                    class="max-w-logo max-h-logo mx-auto block object-contain opacity-50"
                />
            </picture>
        {:else}
            <picture>
                <source
                    srcset={app.dark_image_uri || app.image_uri}
                    media="(prefers-color-scheme: dark)"
                />
                <img
                    src={app.image_uri}
                    alt={app.name}
                    class="max-w-logo max-h-logo stack:mx-auto unstack:mx-0 stack:object-center unstack:object-left block h-auto w-full object-contain object-left"
                />
            </picture>
        {/if}
    </div>
    {#if isDeviceCodeFlow}
        <span
            data-test="app-info-url"
            class="unstack:text-left stack:text-center mt-6 block text-center"
            >({$_('Initiated on device')})</span
        >
    {:else if redirect_uri}
        <span
            data-test="app-info-url"
            class="unstack:text-left stack:text-center mt-6 block text-center"
            >{redirect_uri.host}</span
        >
    {/if}
</section>
