<script>
    import { _ } from 'svelte-i18n'

    let { app = {} } = $props()
</script>

<div
    class="unstack:items-start stack:items-center my-6 flex flex-col items-center"
>
    <span>{$_('By continuing, you agree to the')}</span>

    {#if app?.name}
        <span class="my-0.5 block text-xl font-medium" data-test="app-info-name"
            >{app.name}</span
        >
    {:else}
        <span class="my-0.5 block text-xl font-medium text-red-500"
            >No App Name</span
        >
    {/if}

    <div class="flex gap-x-4 pb-1 text-sm">
        {#if app?.tos_uri}
            <a href={app.tos_uri} class="underline" data-test="app-info-tos"
                >{$_('Terms of Service')}</a
            >
        {:else}
            <span class="text-red-500">{$_('No Terms of Service')}</span>
        {/if}

        {#if app?.pp_uri}
            <a href={app.pp_uri} class="underline" data-test="app-info-pp"
                >{$_('Privacy Policy')}</a
            >
        {:else}
            <span class="text-red-500">{$_('No Privacy Policy')}</span>
        {/if}
    </div>
</div>
