<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { replace as replaceRoute } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onMount, onDestroy } from 'svelte'
    import { getConsent, postLinkProvider } from '$utils/api.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import logins from '$svr/providers/logins.json'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import Dropdown from '$lib/Dropdown.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import { IS_PROD, SLIDE_ANIM_MS } from '$src/constants.js'

    let authCancelledAtInitClient = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let evtSource = $state(null)
    let continueWithEmail = $state(false)

    const existingUsernames = $derived(
        global.data?.release?.existing_usernames &&
            Object.keys(global.data.release.existing_usernames),
    )
    const hasVerifiedUsername = $derived(
        Array.isArray(existingUsernames) && Boolean(existingUsernames?.length),
    )

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we already got an existing_username
        if (global.data.release?.existing_usernames?.length)
            return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/existing-username' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'existing-username',
            server,
        })

        window.location.href = redirect
    }

    function handleWizardComplete() {
        return replaceRoute('/')
    }

    async function verifyEmailSuccess() {
        continueWithEmail = false
        global.data = await getConsent()
    }

    $effect(() => {
        if (hasVerifiedUsername) {
            handleWizardComplete()
        }
    })
</script>

<AuthorizeLayout
    heading="requires a username you already use"
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <section class="container mx-auto max-w-md">
        <h2
            data-test="page-cta"
            class="mx-auto my-6 block text-center text-lg font-medium md:max-w-md"
        >
            Link another account to<br /> verify a username
        </h2>

        <ul class="space-y-3">
            {#each logins.filter((i) => !hasVerifiedUsername && i.claims.existing_username) as provider}
                <LoginProvider
                    {provider}
                    prefix={Array.isArray(global.data?.accounts) &&
                    global.data?.accounts.includes(provider.slug)
                        ? 'Link another'
                        : 'Link'}
                    onclick={(e) => continueWithProvider(provider.slug, e)}
                />
                {#if provider.slug === 'google' && !hasVerifiedUsername}
                    <Dropdown
                        id="email-container"
                        expanded={continueWithEmail}
                        ariaLabel={Array.isArray(global.data?.accounts) &&
                        global.data?.accounts.includes('email')
                            ? $_('Add another email')
                            : $_('Add email')}
                        dataTest="add-email-btn"
                        onclick={() => {
                            continueWithEmail = !continueWithEmail
                        }}
                    >
                        <div
                            class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                        >
                            <MailIcon />
                            <span class="block text-left" aria-hidden="true">
                                {Array.isArray(global.data?.accounts) &&
                                global.data?.accounts.includes('email')
                                    ? $_('Add another email')
                                    : $_('Add email')}
                            </span>
                        </div>
                        {#if continueWithEmail}
                            <div
                                class="px-3 pb-3 pt-1"
                                transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >
                                <VerifyEmail success={verifyEmailSuccess} />
                            </div>
                        {/if}
                    </Dropdown>
                {/if}
            {/each}
        </ul>
    </section>
</AuthorizeLayout>
