<script>
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import { onDestroy, onMount, tick } from 'svelte'
    import intlTelInput from 'intl-tel-input'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import 'intl-tel-input/build/css/intlTelInput.css'
    import logins from '$svr/providers/logins.json'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { IS_PROD } from '$src/constants.js'
    import { preventDefault } from '$utils/common.js'
    import {
        getConsent,
        postLinkPhone,
        postVerifyPhone,
        postLinkPhoneCode,
        postVerifyPhoneCode,
        postLinkProvider,
    } from '$utils/api.js'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import Modal from '$lib/modal/Modal.svelte'
    import VerifyOTP from '$lib/VerifyOTP.svelte'

    let ref

    let evtSource = $state(null)
    let showOTPModal = $state(false)
    let buttonDisabled = $state(true)
    let ajaxRequestResendOTP = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let authCancelledAtInitClient = $state(false)
    let iti = $state(null)
    let otp = $state('')

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we already got a phone
        if (global.data.release?.phones?.length) return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/phone' })

        //we hide spinner here because we want the telephone input to present in DOM for the intlTelInput to find
        global.spinner = false

        await tick() //wait for tel html input to render
        ref = document.getElementById('tel')
        iti = intlTelInput(ref, {
            autoHideDialCode: false,
            utilsScript: window.intlTelInputUtils,
            separateDialCode: true,
        })

        if (ref) {
            ref.focus()
        }

        //populate email input field with unverified phone
        if (global.data.release?.unverified_phones?.length) {
            iti.setNumber(global.data.release.unverified_phones[0])
            buttonDisabled = false
        }
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
        if (iti) {
            iti.destroy()
        }
    })

    let ajaxRequest = $state(false)
    async function verifyPhone(resend = false) {
        ajaxRequest = true
        // Check if user is trying to add unverified phone
        if (
            global.data.release?.unverified_phones?.length &&
            global.data.release?.unverified_phones.includes(iti.getNumber())
        ) {
            resend
                ? await postVerifyPhone(iti.getNumber(), true)
                : await postVerifyPhone(iti.getNumber())
        } else {
            resend
                ? await postLinkPhone(iti.getNumber(), true)
                : await postLinkPhone(iti.getNumber())
        }
        showOTPModal = true
        ajaxRequest = ajaxRequestResendOTP = false
    }

    let ajaxRequestSendOTP = $state(false)
    async function verifyPhoneCode(code) {
        ajaxRequestSendOTP = true
        let json
        //Check if user is trying to add unverified phone
        if (
            global.data.release?.unverified_phones?.length &&
            global.data.release?.unverified_phones.includes(iti.getNumber())
        ) {
            json = await postVerifyPhoneCode(code)
        } else {
            json = await postLinkPhoneCode(code, false)
        }
        if (json.error) {
            otp = ''
            ajaxRequestSendOTP = false
        } else {
            notification.clear() //remove outstanding notifications
            global.data = {} //get latest state from / root page
            iti.destroy()
            navigateTo('/')
        }
    }

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'phone',
            server,
        })
        window.location.href = redirect
    }

    $effect(() => {
        if (
            !global.data?.isPersonalLoggedIn &&
            !global.data?.isManagedLoggedIn
        ) {
            if (global.data.release?.phones?.length) replaceRoute('/')
        }
    })
</script>

<AuthorizeLayout
    heading={$_('Requires your phone')}
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <form
        class="mx-auto mt-2 md:max-w-md"
        onsubmit={preventDefault(() => verifyPhone(false))}
    >
        <div class="relative">
            <!-- svelte-ignore a11y_autofocus -->
            <input
                type="tel"
                name="tel"
                class="h-12 w-full px-4"
                required
                id="tel"
                autofocus
                autocomplete="tel"
                oninput={() => {
                    buttonDisabled = !iti.isValidNumber()
                }}
                placeholder={$_('enter your phone')}
            />

            {#if showOTPModal}
                <Modal position="center" close={() => (showOTPModal = false)}>
                    <VerifyOTP
                        bind:otp
                        bind:ajaxRequestSendOTP
                        bind:ajaxRequestResendOTP
                        verifyCode={verifyPhoneCode}
                        resend={() => verifyPhone(true)}
                    />
                </Modal>
            {/if}
        </div>

        <button
            data-test="send-verification-code-btn"
            type="submit"
            disabled={buttonDisabled || ajaxRequest}
            class="btn-background mt-2 inline-flex w-full items-center justify-center transition disabled:opacity-50"
        >
            {#if ajaxRequest}
                <SpinnerIcon css="h-5 w-5 text-white" />
            {:else}
                {$_('Send verification code')}
            {/if}
        </button>
    </form>

    {#if logins.filter((i) => i.claims.verified_phone).length}
        <section class="container mx-auto space-y-2 md:max-w-md">
            {#each logins.filter((i) => i.claims.verified_phone) as provider}
                <div>
                    <LoginProvider
                        onclick={(e) => continueWithProvider(provider.slug, e)}
                        {provider}
                        prefix={$_('Get phone from {provider}')}
                    />
                </div>
            {/each}
        </section>
    {/if}
</AuthorizeLayout>
