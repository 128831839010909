import QRCode from 'qrcode'

const generateQR = async (text) => {
    return await QRCode.toString(text, {
        type: 'svg',
        color: {
            light: '#0000', // Transparent background
        },
    })
}

export { generateQR }
