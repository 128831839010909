<script>
    import Copy from './Copy.svelte'

    let { link, dataTest = '' } = $props()
</script>

<span
    class="mt-6 flex items-center space-x-2 rounded-md bg-[#DCDCDC] px-2 py-2 dark:bg-[#505050]"
>
    <span data-test={dataTest}>{link}</span>
    <Copy content={link} />
</span>
