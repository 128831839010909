<script>
    import { _ } from 'svelte-i18n'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'
    import CloseIcon from '$lib/icon/CloseIcon.svelte'

    let { deleteQRCode = () => {}, qrSvg, qrLink } = $props()

    let qrLinkCopied = $state(false)

    async function copyQRLink() {
        qrLinkCopied = true
        await navigator.clipboard.writeText(qrLink)
        setTimeout(() => {
            qrLinkCopied = false
        }, 1000)
    }
</script>

<FullPageModal close={deleteQRCode} dataTest="qr-modal">
    <div class="flex justify-center">
        <h1 class="text-xl">{$_('Scan QR Code')}</h1>
        <button class="absolute right-5" onclick={deleteQRCode}>
            <CloseIcon css="h-6 w-6" />
        </button>
    </div>
    <div class="w-full dark:invert">
        {@html qrSvg}
    </div>
    <button data-test="copy-qr-btn" onclick={copyQRLink}
        >{qrLinkCopied ? 'Copied to clipboard!' : 'Copy to clipboard'}</button
    >
</FullPageModal>
