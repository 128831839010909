<script>
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
        location,
    } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onDestroy, onMount } from 'svelte'
    import {
        getConsent,
        postLinkEth,
        postLinkEthChallenge,
        postLinkProvider,
    } from '$utils/api.js'
    import { getWallet, trimEthAddress } from '$utils/ethereum'
    import { logPlausibleEvent } from '$utils/plausible'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import logins from '$svr/providers/logins.json'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'
    import { IS_PROD } from '$src/constants.js'

    let provider = $state()

    let ethereumProgressModal = $state(null) //Will hold extension values
    let ethereumProgressNotifs = $state([])
    let authCancelledAtRemoteClient = $state(false)
    let evtSource = $state(null)
    let authCancelledAtInitClient = $state(false)

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        const providerSlug = $location.split('/')[2] //gets the "ethereum" from /wizard/ethereum
        provider = [
            ...logins,
            {
                // add ethereum obj since it does not exist in logins.json
                slug: 'ethereum',
                display: 'Ethereum',
            },
        ].find((i) => i.slug === providerSlug)

        if (!provider)
            //reqeusted scope does not exist in logins.json - this should not happen but just in case
            return replaceRoute('/')

        //we already got the claim for the scope
        if (global.data.release[provider.slug + 's']?.length) {
            return replaceRoute('/')
        }

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/' + providerSlug })
        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function continueWithEthereumExtension() {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        continueEthExtensionSigning(address)
    }

    async function continueEthExtensionSigning(address) {
        let challenge, signature

        const res = await postLinkEth(address)
        logPlausibleEvent({
            u: `/start/link/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Message signed'),
                    type: 'success',
                    status: $_('Linking wallet'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_(`You've rejected the sign request`),
                    'error',
                )
            } else {
                notification.show(
                    $_('Something went wrong. Please try again later.'),
                    'error',
                )
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
        }

        await postLinkEthChallenge(body)
        logPlausibleEvent({
            u: `/link/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        global.data = await getConsent()
        if (!global.data.merge) {
            navigateTo('/')
        }
        ethereumProgressModal = null
        ethereumProgressNotifs = []
    }

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({ slug, server })
        window.location.href = redirect
    }
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

<AuthorizeLayout
    heading={provider?.slug === 'ethereum'
        ? $_('Requires your ethereum address')
        : `Requires your ${provider?.display} username`}
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <div class="mt-2">
        {#if provider?.slug === 'ethereum'}
            {#if window.ethereum?.isMetaMask}
                <LoginProvider
                    onclick={continueWithEthereumExtension}
                    provider={{ display: 'MetaMask', slug: 'metamask' }}
                    prefix="Get from"
                />
            {:else if window.ethereum}
                <LoginProvider
                    onclick={continueWithEthereumExtension}
                    provider={{ display: 'Ethereum', slug: 'ethereum' }}
                    prefix="Get from"
                />
            {:else}
                <span class="my-6 block text-center text-sm text-red-500"
                    >Please install and/or activate your Ethereum browser
                    extension and reload this page.</span
                >
            {/if}
        {:else}
            <LoginProvider
                onclick={(e) => continueWithProvider(provider.slug, e)}
                {provider}
                prefix="Get username from"
            />
        {/if}
    </div>
</AuthorizeLayout>
