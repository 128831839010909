<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { DEFAULT_MANAGED_LOGO, SLIDE_ANIM_MS } from '$src/constants.js'
    import { dedupe, preventDefault } from '$utils/common'
    import { putName } from '$utils/api.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        handleDropdown = () => {},
    } = $props()

    let value = $state('')
    let addValueExpand = $state(false)

    let addFullNameAjax = $state(false)
    async function addFullName() {
        addFullNameAjax = true
        const { names } = await putName('name', value)
        if (names) {
            global.data.release.names = names
            selected = global.data.release.names[0]
            expanded = false
            addValueExpand = false
            value = ''
        }
        addFullNameAjax = false
    }
</script>

<div data-test="full-name-container" class="space-y-1">
    <label for="full-name" class="text-sm font-medium">
        {$_('Full Name (legal name)')}
    </label>
    {#if global.data?.release?.managed?.name}
        {@const meta = global.data?.release?.managed?._meta}
        <div
            class="bg-charcoal block flex h-12 items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
        >
            <span class="managed-rounded-square-sm">
                <img
                    src={meta.logo || DEFAULT_MANAGED_LOGO}
                    alt={meta.domain || 'logo'}
                    class="w-4.5 max-h-[18px] object-contain"
                />
            </span>
            <span class="ml-4">{global.data?.release?.managed?.name}</span>
        </div>
    {:else if global.data?.release?.update_scope}
        <div class="box-shadow mt-2 space-y-2 rounded-md p-2">
            <div data-test="names-container" class="relative flex items-center">
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each dedupe(global.data?.release?.names) as name, index (name)}
                            <li
                                data-test="name-{index}"
                                class="flex w-full items-center"
                            >
                                <input
                                    id="name-{name}"
                                    name="name"
                                    type="radio"
                                    bind:group={selected}
                                    value={name}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                />
                                <label
                                    for="name-{name}"
                                    class="ml-2.5 inline-flex w-full items-center truncate"
                                >
                                    <span class="truncate">
                                        {name}
                                    </span>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <div class="relative">
                <Dropdown
                    hasBackground={false}
                    dataTest="add-name-btn"
                    ariaLabel={$_('Add another name')}
                    expanded={addValueExpand}
                    onclick={() => (addValueExpand = !addValueExpand)}
                >
                    <div
                        class="flex h-12 items-center px-4 text-left"
                        aria-hidden="true"
                    >
                        {$_('Add another name')}
                    </div>

                    {#if addValueExpand}
                        <form
                            onsubmit={preventDefault(addFullName)}
                            class="p-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            <input
                                type="text"
                                name="name"
                                id="name"
                                bind:value
                                autocomplete="name"
                                required
                                placeholder={$_('enter your full name')}
                                class="h-12 w-full px-[16px] sm:px-[18px]"
                            />
                            <button
                                data-test="add-name-btn"
                                type="submit"
                                disabled={!value.length || addFullNameAjax}
                                class="btn-background mt-2 flex w-full flex-shrink-0 items-center justify-center disabled:opacity-50"
                            >
                                {#if addFullNameAjax}
                                    <SpinnerIcon css="h-5 w-5 text-white" />
                                {:else if global.data.release.names.length}
                                    <span>{$_('Add another name')}</span>
                                {:else}
                                    <span>{$_('Add name')}</span>
                                {/if}
                            </button>
                        </form>
                    {/if}
                </Dropdown>
            </div>
        </div>
    {:else}
        <Dropdown
            hasBackground={false}
            dataTest="name-dropdown-btn"
            ariaLabel={selected}
            id="name-dropdown"
            {expanded}
            onclick={() => handleDropdown('name')}
        >
            <div
                data-test="selected-name"
                class="flex h-12 w-full items-center justify-between px-2"
                aria-hidden="true"
            >
                {selected}
            </div>

            {#if expanded}
                {@const names = Array.isArray(global.data?.release?.names)
                    ? dedupe(global.data.release.names).filter(
                          (i) => i !== selected,
                      )
                    : []}
                <div
                    class="px-indent flex w-full flex-col rounded-md text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul
                        class="mt-0.5 {names.length
                            ? 'border-y'
                            : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                    >
                        {#each names as name, index (name)}
                            <li>
                                <button
                                    data-test={`name-${index}`}
                                    onclick={() => {
                                        selected = name
                                        expanded = false
                                    }}
                                    class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                                >
                                    {name}
                                </button>
                            </li>
                        {/each}
                    </ul>
                    <form onsubmit={preventDefault(addFullName)} class="my-2">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            bind:value
                            autocomplete="name"
                            required
                            placeholder={$_('enter your full name')}
                            class="h-12 w-full px-[16px] sm:px-[18px]"
                        />
                        <button
                            data-test="add-name-btn"
                            type="submit"
                            disabled={!value.length || addFullNameAjax}
                            class="btn-background mt-2 flex w-full flex-shrink-0 items-center justify-center disabled:opacity-50"
                        >
                            {#if addFullNameAjax}
                                <SpinnerIcon css="h-5 w-5 text-white" />
                            {:else if global.data?.release?.names?.length}
                                <span>{$_('Add another name')}</span>
                            {:else}
                                <span>{$_('Add name')}</span>
                            {/if}
                        </button>
                    </form>
                </div>
            {/if}
        </Dropdown>
    {/if}
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }

    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
