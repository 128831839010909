<script>
    let { unverified = false } = $props()
</script>

{#if unverified}
    <svg
        class="h-5.5 w-5.5"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M44.6586 16.8535C47.8536 18.4878 50 21.5122 50 25C50 28.4878 47.8536 31.5122 44.6586 33.1465C45.7805 36.5366 45.1464 40.2197 42.683 42.6831C40.2195 45.1465 36.5366 45.7559 33.1464 44.6587C31.5366 47.8535 28.4878 50 25 50C21.5122 50 18.4878 47.8535 16.8536 44.6587C13.4634 45.7559 9.78052 45.1465 7.31702 42.6831C4.85364 40.2197 4.2439 36.5366 5.36584 33.1465C2.17078 31.5122 0 28.4878 0 25C0 21.5122 2.17078 18.4878 5.36584 16.8535C4.2439 13.4634 4.85364 9.78027 7.31702 7.31689C9.78052 4.85352 13.4634 4.21973 16.8781 5.34131C18.4878 2.14648 21.5122 0 25 0C28.4878 0 31.5366 2.14648 33.1464 5.34131C36.5366 4.21973 40.2195 4.85352 42.683 7.31689C45.1464 9.78027 45.7805 13.4634 44.6586 16.8535Z"
            fill="currentColor"
            fill-opacity="75%"
        />
    </svg>
{:else}
    <svg
        id="verified-icon"
        class="h-5.5 w-5.5"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M50 25C50 21.5122 47.8537 18.4878 44.6585 16.8537C45.7805 13.4634 45.1463 9.78049 42.6829 7.31707C40.2195 4.85366 36.5366 4.21951 33.1463 5.34146C31.5366 2.14634 28.4878 0 25 0C21.5122 0 18.4878 2.14634 16.878 5.34146C13.4634 4.21951 9.78049 4.85366 7.31707 7.31707C4.85366 9.78049 4.2439 13.4634 5.36585 16.8537C2.17073 18.4878 0 21.5122 0 25C0 28.4878 2.17073 31.5122 5.36585 33.1463C4.2439 36.5366 4.85366 40.2195 7.31707 42.6829C9.78049 45.1463 13.4634 45.7561 16.8537 44.6585C18.4878 47.8537 21.5122 50 25 50C28.4878 50 31.5366 47.8537 33.1463 44.6585C36.5366 45.7561 40.2195 45.1463 42.6829 42.6829C45.1463 40.2195 45.7805 36.5366 44.6585 33.1463C47.8537 31.5122 50 28.4878 50 25ZM21.439 35.2439L12.3171 26.122L15.7561 22.6585L21.2683 28.1707L32.9756 15.4146L36.561 18.7317L21.439 35.2439Z"
            fill="#6364FF"
        />
    </svg>
{/if}
