<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { dedupe, preventDefault } from '$utils/common'
    import { putName } from '$utils/api.js'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        handleDropdown = () => {},
    } = $props()

    let value = $state('')

    let addPreferredNameAjax = $state(false)
    async function addPreferredName() {
        addPreferredNameAjax = true
        const { nicknames } = await putName('nickname', value)
        if (nicknames) {
            global.data.release.nicknames = nicknames
            selected = global.data.release.nicknames[0]
            expanded = false
            value = ''
        }
        addPreferredNameAjax = false
    }
</script>

<div data-test="preferred-name-container" class="space-y-1">
    <label for="preferred-name" class="text-sm font-medium">
        {$_('Preferred Name (what you want to be called)')}
    </label>
    <Dropdown
        hasBackground={false}
        dataTest="preferred-name-dropdown-btn"
        ariaLabel={selected}
        id="preferred-name-dropdown"
        {expanded}
        onclick={() => handleDropdown('preferredName')}
    >
        <div
            data-test="selected-preferred-name"
            class="flex h-12 w-full items-center justify-between px-2"
            aria-hidden="true"
        >
            {selected}
        </div>
        {#if expanded}
            {@const nicknames = Array.isArray(global.data?.release?.nicknames)
                ? dedupe(global.data.release.nicknames).filter(
                      (i) => i !== selected,
                  )
                : []}
            <div
                class="px-indent flex w-full flex-col rounded-md text-left"
                transition:slide={{ duration: SLIDE_ANIM_MS }}
            >
                <ul
                    class="mt-0.5 {nicknames.length
                        ? 'border-y'
                        : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                >
                    {#each nicknames as name, index (name)}
                        <li>
                            <button
                                data-test={`preferred-name-${index}`}
                                onclick={() => {
                                    selected = name
                                    expanded = false
                                }}
                                class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                            >
                                {name}
                            </button>
                        </li>
                    {/each}
                </ul>

                <form onsubmit={preventDefault(addPreferredName)} class="my-2">
                    <input
                        type="text"
                        name="nickname"
                        id="nickname"
                        bind:value
                        autocomplete="nickname"
                        required
                        placeholder={$_('enter what you want to be called')}
                        class="h-12 w-full px-[16px] sm:px-[18px]"
                    />
                    <button
                        data-test="add-preferred-name-btn"
                        type="submit"
                        disabled={!value.length || addPreferredNameAjax}
                        class="btn-background mt-2 flex w-full flex-shrink-0 items-center justify-center disabled:opacity-50"
                    >
                        {#if addPreferredNameAjax}
                            <SpinnerIcon css="h-5 w-5 text-white" />
                        {:else if global.data?.release?.nicknames?.length}
                            <span>{$_('Add another preferred name')}</span>
                        {:else}
                            <span>{$_('Add preferred name')}</span>
                        {/if}
                    </button>
                </form>
            </div>
        {/if}
    </Dropdown>
</div>

<style>
    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
