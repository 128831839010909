<script>
    import { notification } from '$src/stores.js'
    import CheckIcon from '$lib/icon/CheckIcon.svelte'
    import CopyIcon from '$lib/icon/CopyIcon.svelte'

    let { content = '' } = $props()

    let copied = $state(false)
    async function copy() {
        copied = true
        try {
            await navigator.clipboard.writeText(content)
        } catch (err) {
            console.error('Failed to copy to clipboard', err)
            notification.show('Failed to copy to clipboard', 'error')
        }
        setTimeout(() => {
            copied = false
        }, 1000)
    }
</script>

<button onclick={copy} class="w-5">
    {#if copied}
        <CheckIcon />
    {:else}
        <CopyIcon />
    {/if}
</button>
