<script>
    import { onMount } from 'svelte'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import ChooseManaged from '$lib/ChooseManaged.svelte'
    import { getConsent, getManagedLogos } from '$utils/api.js'
    import { global } from '$src/state.svelte.js'
    import { logPlausibleEvent } from '$utils/plausible'
    import { replace as replaceRoute } from 'svelte-spa-router'

    let states = $state({
        PICK_IMAGE: false,
    })
    let managedLogos = $state(null)

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (global.data.chooseManagedLogo) {
            managedLogos = await getManagedLogos(
                global.data.chooseManagedLogo.id,
            )
            states.PICK_IMAGE = true
        }

        if (!global.data.chooseManagedLogo && !global.data.chooseWhoManages)
            return replaceRoute('/')

        logPlausibleEvent({ u: '/wizard/managed' })

        global.spinner = false
    })
</script>

<AuthorizeLayout
    showAppInfo={false}
    unstack={false}
    showFooter={false}
    showCancelConsent={false}
>
    <ChooseManaged bind:states {managedLogos} />
</AuthorizeLayout>
