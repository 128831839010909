<script>
    import { _ } from 'svelte-i18n'
    import { replace as replaceRoute } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onDestroy, onMount } from 'svelte'
    import { getConsent, postLinkProvider } from '$utils/api.js'
    import { makeLabel } from '$utils/common.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import VerifiedIcon from '$lib/icon/VerifiedIcon.svelte'
    import logins from '$svr/providers/logins.json'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import { IS_PROD } from '$src/constants.js'

    let authCancelledAtRemoteClient = $state(false)
    let authCancelledAtInitClient = $state(false)
    let evtSource = $state(null)

    const names = $derived(
        global.data?.release?.verified_names &&
            Object.keys(global.data?.release?.verified_names),
    )
    const verifiedNames = $derived(
        names &&
            names.filter(
                (i) =>
                    Object.keys(global.data?.release?.verified_names[i])
                        .length >= 2,
            ),
    )
    const hasVerifiedName = $derived(Boolean(verifiedNames?.length))

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we alraedy got a verified_name
        if (global.data.release?.verified_names?.length)
            return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/verified-name' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'verified-name',
            server,
        })

        window.location.href = redirect
    }

    function handleWizardComplete() {
        return replaceRoute('/')
    }

    $effect(() => {
        if (hasVerifiedName) {
            handleWizardComplete()
        }
    })
</script>

<AuthorizeLayout
    heading="Requires a name you already use in two different accounts"
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <section class="container mx-auto max-w-md">
        {#if global.data.release?.verified_names}
            <div>
                <h3
                    class="mr-auto flex items-center justify-start text-left text-sm font-medium"
                >
                    {$_('Name')}
                    {#if !Object.keys(global.data.release.verified_names).filter((i) => Object.keys(global.data.release.verified_names[i]).length >= 2).length}
                        <span
                            data-test="no-name-fallback"
                            class="ml-2 font-normal italic opacity-60"
                        >
                            {$_('none verified yet')}
                        </span>
                    {/if}
                </h3>
                <ul
                    class="ml-4 mt-1 flex flex-col items-start space-y-2 truncate"
                >
                    {#each Object.keys(global.data.release.verified_names) as verified_name, index}
                        {@const isUnverified =
                            Object.keys(
                                global.data.release.verified_names[
                                    verified_name
                                ],
                            ).length < 2}
                        <li
                            data-test="name-{index}"
                            class:opacity-60={isUnverified}
                            class="inline-flex w-full items-center truncate"
                        >
                            <span class="mr-0.5 inline-block w-7 flex-shrink-0">
                                <VerifiedIcon unverified={isUnverified} />
                            </span>
                            <span class="truncate">
                                {verified_name +
                                    ' ' +
                                    makeLabel(
                                        global.data.release.verified_names[
                                            verified_name
                                        ],
                                    )}
                            </span>
                        </li>
                    {/each}
                </ul>
            </div>
        {/if}

        <h2
            data-test="page-cta"
            class="mx-auto mb-6 mt-8 block text-center text-lg font-medium md:max-w-md"
        >
            Link another account to<br /> verify your name
        </h2>

        <ul class="space-y-3">
            {#each logins.filter((i) => !hasVerifiedName && i.claims.existing_name) as provider}
                <LoginProvider
                    {provider}
                    prefix={Array.isArray(global.data?.accounts) &&
                    global.data?.accounts.includes(provider.slug)
                        ? 'Link another'
                        : 'Link'}
                    onclick={(e) => continueWithProvider(provider.slug, e)}
                />
            {/each}
        </ul>
    </section>
</AuthorizeLayout>
