<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import logins from '$svr/providers/logins.json'
    import { global } from '$src/state.svelte.js'
    import { dedupe, makeLabel } from '$utils/common'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import VerifiedIcon from '$lib/icon/VerifiedIcon.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        handleDropdown = () => {},
        continueWithProvider = () => {},
    } = $props()
</script>

<div data-test="verified-name-container" id="verified-name" class="space-y-1">
    <label for="verified-name" class="text-sm font-medium">
        {$_('Name')}
    </label>
    <div id="verified-name" class="box-shadow space-y-2 rounded-md p-2">
        {#if selected && global.data?.release?.verified_names}
            {@const verifiedNames = dedupe([
                ...Object.keys(global.data.release.verified_names),
            ]).sort(
                (a, b) =>
                    Object.keys(global.data.release.verified_names[b]).length -
                    Object.keys(global.data.release.verified_names[a]).length,
            )}
            <div
                data-test="verified-name-container"
                class="relative flex items-center"
            >
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each verifiedNames as verified_name, index (verified_name)}
                            {@const disabled =
                                Object.keys(
                                    global.data.release.verified_names[
                                        verified_name
                                    ],
                                ).length < 2}
                            <li
                                data-test="verified-name-{index}"
                                class:opacity-50={disabled}
                                class:pointer-events-none={disabled}
                                class="flex w-full items-center"
                            >
                                <input
                                    id="verified-name-{verified_name}"
                                    name="verified-name"
                                    type="radio"
                                    bind:group={selected}
                                    value={verified_name}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                    {disabled}
                                />
                                <label
                                    for="verified-name-{verified_name}"
                                    class="ml-2 inline-flex w-full items-center truncate"
                                >
                                    <span
                                        class="mr-0.5 inline-block w-7 flex-shrink-0"
                                    >
                                        <VerifiedIcon unverified={disabled} />
                                    </span>
                                    <span class="truncate">
                                        {verified_name +
                                            ' ' +
                                            makeLabel(
                                                global.data.release
                                                    .verified_names[
                                                    verified_name
                                                ],
                                            )}
                                    </span>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>
        {/if}

        <div class="relative">
            <span
                data-test="link-more-name-cta"
                class="mb-1 block italic opacity-80"
            >
                Link another account to verify a different name
            </span>
            <Dropdown
                hasBackground={false}
                dataTest="add-provider-btn"
                ariaLabel={$_('Link another account')}
                {expanded}
                onclick={() => handleDropdown('verifiedNameAccounts')}
            >
                <div
                    class="flex h-12 items-center px-4 text-left"
                    aria-hidden="true"
                >
                    {$_('Link another account')}
                </div>

                {#if expanded}
                    <ul
                        class="space-y-2 p-2"
                        transition:slide={{ duration: SLIDE_ANIM_MS }}
                    >
                        {#each logins.filter((i) => i.claims.existing_name) as provider}
                            <LoginProvider
                                {provider}
                                prefix={Array.isArray(global.data?.accounts) &&
                                global.data?.accounts.includes(provider.slug)
                                    ? 'Link another'
                                    : 'Link'}
                                onclick={(e) =>
                                    continueWithProvider(
                                        provider.slug,
                                        null,
                                        e,
                                    )}
                            />
                        {/each}
                    </ul>
                {/if}
            </Dropdown>
        </div>
    </div>
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }
</style>
