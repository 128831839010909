import { mount } from 'svelte'
import Authorize from './pages/Authorize.svelte'
import './index.css'
import './i18n.js'
import 'intl-tel-input/build/js/utils.js'

// act as const referenced globally
window.isWalletAuthorizeApp = () => true
window.isWalletApp = () => false
window.isWalletInviteApp = () => false

const app = mount(Authorize, { target: document.body })

export default app
