<script>
    import { onDestroy, onMount } from 'svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { getConsent, postLoginProvider } from '$utils/api.js'
    import { processProviderHintParam } from '$utils/consent.js'
    import { replace as replaceRoute } from 'svelte-spa-router'
    import { clearSessionStorage } from '$utils/storage.js'
    // import { promptForPasskey } from '$utils/passkey.js';
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { IS_PROD } from '$src/constants.js'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import LoginAuthorize from '$lib/LoginAuthorize.svelte'

    //on logout, get original query params from session storage to make get consent call
    const authorizeQueryParams = sessionStorage.getItem(
        'authorize_query_params',
    )

    let evtSource,
        showPasskeyLogin = false

    let authCancelledAtInitClient = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let remoteAuthDone = $state(false)
    let recommendedProviders = $state([])

    onMount(async () => {
        global.spinner = true

        //remove all outstanding modals
        global.upgradePreferred = null
        global.mergeAccounts = null

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent(authorizeQueryParams || '')
        }

        //auto-login if login_hint with known managed account
        const loginHintManaged = global.data?.login_hint?.managed
        const isLoginHintKnownManaged =
            loginHintManaged?.slug && loginHintManaged?.slug !== 'email'
        if (loginHintManaged && isLoginHintKnownManaged) {
            global.spinner = true
            const body = {
                accountToUse: 'managed',
                accountSelected: 'managed',
            }
            if (loginHintManaged.login_hint)
                body.login_hint = loginHintManaged.login_hint
            const { redirect } = await postLoginProvider({
                slug: loginHintManaged.slug,
                body,
            })
            if (redirect) return (window.location.href = redirect)
            throw new Error('redirect missing')
        }

        if (
            (global.data?.isPersonalLoggedIn ||
                global.data?.isManagedLoggedIn) &&
            'release' in global.data &&
            !global.data.login_hint &&
            !global.data?.release?.requiresPersonal
        ) {
            await logPlausibleEvent({ u: '/login/auto' })
            return replaceRoute('/')
        }

        recommendedProviders = processProviderHintParam(
            authorizeQueryParams,
            global.data,
        )

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        // uncomment to start prompting for passkey
        // showPasskeyLogin = await promptForPasskey();

        logUserReleaseFunnelPlausibleEvent()
        clearSessionStorage([
            'app',
            'authorize_query_params',
            'az_release_funnel',
        ])
        logPlausibleEvent({ u: '/login' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    function logUserReleaseFunnelPlausibleEvent() {
        //Start of New User Release Funnel
        //event not already sent + no Hellō cookie
        if (
            !sessionStorage.az_release_funnel &&
            !global.data.isPersonalLoggedIn &&
            !global.data.isManagedLoggedIn &&
            !global.data.preferred
        ) {
            const _recommendedProviders = Array.from(recommendedProviders)
            if (showPasskeyLogin) {
                _recommendedProviders.push('passkey')
            }
            const client_id =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'client_id',
                ) || global?.data?.app?.client_id
            const redirect_uri =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'redirect_uri',
                ) || global?.data?.app?.redirect_uri
            let redirect
            try {
                redirect = new URL(redirect_uri)?.hostname
            } catch (err) {
                console.error(err)
            }
            logPlausibleEvent({
                n: 'AZ Request',
                p: {
                    client_id,
                    recommended_providers: _recommendedProviders
                        .sort()
                        .join(' '),
                    redirect,
                },
                u: '/login',
            })
            sessionStorage.setItem('az_release_funnel', 'az_request')
        }
    }

    async function handleRemoteAuthSuccess() {
        remoteAuthDone = true
        notification.show('Authorization is complete', 'success')
        if (evtSource) {
            evtSource.close()
        }
        return (global.spinner = false)
    }
</script>

<AuthorizeLayout
    showTitleBar={!remoteAuthDone &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !remoteAuthDone &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={remoteAuthDone ||
        authCancelledAtRemoteClient ||
        authCancelledAtInitClient}
    showCancelConsent={false}
    bind:authCancelledAtRemoteClient
>
    <LoginAuthorize
        showQRLogin={true}
        {recommendedProviders}
        {handleRemoteAuthSuccess}
    />
</AuthorizeLayout>
