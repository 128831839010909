<script>
    import { _ } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { preventDefault } from '$utils/common.js'
    import { deleteConsent, deleteConsentOnly } from '$utils/api.js'
    import AppInfo from '$lib/AppInfo.svelte'
    import DeviceInfo from '$lib/DeviceInfo.svelte'
    import Notification from '$lib/Notification.svelte'
    import AuthorizeTitleBar from '$lib/AuthorizeTitleBar.svelte'
    import AuthorizeFooter from '$lib/AuthorizeFooter.svelte'

    let {
        preHeading = '',
        heading = '',
        showPreHeading = false,
        showHeading = true,
        showAppInfo = true,
        showTitleBar = true,
        showDeviceInfo = false,
        unstack = true,
        showFooter = true,
        closePageState = false,
        children,
        showCancelConsent = true,
        cancelConsent = defaultCancelConsent,
        authCancelledAtRemoteClient = $bindable(false),
        evtSource = null,
    } = $props()

    async function defaultCancelConsent() {
        sessionStorage.removeItem('az_release_funnel')
        if (global.isRemoteAuthClient) {
            await deleteConsentOnly()
            notification.show('Authorization was cancelled', 'error')
            authCancelledAtRemoteClient = true
            if (evtSource) {
                evtSource.close()
            }
        } else {
            deleteConsent()
        }
    }
</script>

{#if showTitleBar}
    <AuthorizeTitleBar />
{/if}

{#if $notification.text}
    <Notification css="mt-2 rounded-md" />
{/if}

{#if !global.spinner && Object.keys(global.data).length}
    {#if showDeviceInfo}
        <DeviceInfo onclick={cancelConsent} />
    {/if}

    <main
        class="{unstack
            ? 'unstack:items-start unstack:flex-row stack:flex-col stack:items-center'
            : ''} stack:py-[5vh] gap-x-col flex flex-col items-center py-4 [@media(min-height:1216px)]:!py-16"
    >
        {#if closePageState}
            {$_('You can now close this window')}
        {:else}
            {#if showAppInfo && global.data.app}
                <AppInfo
                    app={global.data.app}
                    isDeviceCodeFlow={global.data.isDeviceCodeFlow}
                />
            {/if}

            <div
                class="{unstack
                    ? 'unstack:w-1/2 stack:w-full'
                    : ''} max-w-col w-full"
            >
                {#if showPreHeading && preHeading?.length}
                    <h1
                        data-test="page-pre-heading"
                        class="stack:text-center stack:mb-8 relative mb-4 text-xl font-medium"
                    >
                        {@html preHeading}
                    </h1>
                {/if}
                {#if showHeading && heading?.length}
                    <h1 data-test="page-heading" class="relative font-medium">
                        {@html heading}
                    </h1>
                {/if}

                <div class="px-indent" class:mt-2={showHeading}>
                    {@render children?.()}

                    {#if showCancelConsent}
                        <div class="mt-4 flex justify-start">
                            <button
                                data-test="cancel-btn"
                                type="button"
                                onclick={preventDefault(cancelConsent)}
                                class="btn-border w-full">{$_('Cancel')}</button
                            >
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
    </main>

    {#if !global.data.isPersonalLoggedIn && !global.data?.isManagedLoggedIn && !global.data.preferred && !global.data?.login_hint && !closePageState && showFooter}
        <AuthorizeFooter />
    {/if}
{/if}
