<script>
    import { global } from '$src/state.svelte.js'
</script>

<div data-test="api-access-container" class="space-y-1">
    <label for="api-access" class="text-sm font-medium">API Access</label>
    <div
        id="api-access"
        data-test="publisher-api-access-container"
        class="ring-charcoal text-charcoal relative h-auto w-full rounded-md px-2 py-2 ring-1 ring-opacity-60 dark:text-[#D4D4D4] dark:ring-[#808080] dark:ring-opacity-60"
    >
        <ul class="space-y-1">
            {#each ['Create', 'Read', 'Update', 'Delete'] as op}
                <li class="flex items-center gap-x-2 text-left">
                    <input
                        data-test={`publisher-${op}-checkbox`}
                        id={`publisher-${op}-checkbox`}
                        type="checkbox"
                        class="form-checkbox !pointer-events-none !h-4 !w-4 opacity-60"
                        checked={global.data.release.console ||
                            (['Create', 'Read'].includes(op) &&
                                global.data.release.quickstart)}
                        disabled
                    />
                    <label
                        for={`publisher-${op}-checkbox`}
                        class="pointer-events-none">Publisher {op}</label
                    >
                </li>
            {/each}
        </ul>
    </div>
    <div
        data-test="application-api-access-container"
        class="ring-charcoal text-charcoal relative mt-2 h-auto w-full rounded-md px-2 py-2 ring-1 ring-opacity-60 dark:text-[#D4D4D4] dark:ring-[#808080] dark:ring-opacity-60"
    >
        <ul class="space-y-1">
            {#each ['Create', 'Read', 'Update', 'Delete'] as op}
                <li class="flex items-center gap-x-2 text-left">
                    <input
                        type="checkbox"
                        data-test={`application-${op}-checkbox`}
                        id={`application-${op}-checkbox`}
                        class="form-checkbox !pointer-events-none !h-4 !w-4 opacity-60"
                        checked={global.data.release.console ||
                            (['Create', 'Read'].includes(op) &&
                                global.data.release.quickstart)}
                        disabled
                    />
                    <label
                        for={`application-${op}-checkbox`}
                        class="pointer-events-none">Application {op}</label
                    >
                </li>
            {/each}
        </ul>
    </div>
</div>
