<script>
    import { replace as replaceRoute } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onDestroy, onMount } from 'svelte'
    import { getConsent, postLinkProvider } from '$utils/api.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import logins from '$svr/providers/logins.json'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import { IS_PROD } from '$src/constants.js'

    let authCancelledAtInitClient = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let evtSource = $state(null)

    const existingNames = $derived(
        global.data?.release?.existing_names &&
            Object.keys(global.data.release.existing_names),
    )
    const hasVerifiedName = $derived(
        Array.isArray(existingNames) && Boolean(existingNames?.length),
    )

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we already got an existing_name
        if (global.data.release?.existing_names?.length)
            return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/existing-name' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'existing-name',
            server,
        })

        window.location.href = redirect
    }

    function handleWizardComplete() {
        return replaceRoute('/')
    }

    $effect(() => {
        if (hasVerifiedName) {
            handleWizardComplete()
        }
    })
</script>

<AuthorizeLayout
    heading="Requires a name you already use"
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <section class="container mx-auto max-w-md">
        <h2
            data-test="page-cta"
            class="mx-auto my-6 block text-center text-lg font-medium md:max-w-md"
        >
            Link another account to<br /> verify a name
        </h2>
        <ul class="space-y-3">
            {#each logins.filter((i) => !hasVerifiedName && i.claims.existing_name) as provider}
                <LoginProvider
                    {provider}
                    prefix={Array.isArray(global.data?.accounts) &&
                    global.data?.accounts.includes(provider.slug)
                        ? 'Link another'
                        : 'Link'}
                    onclick={(e) => continueWithProvider(provider.slug, e)}
                />
            {/each}
        </ul>
    </section>
</AuthorizeLayout>
