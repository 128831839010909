<script>
    import { _ } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { getDeviceInfo } from '$utils/device.js'
    import DesktopIcon from '$lib/icon/DesktopIcon.svelte'
    import AppleIcon from '$lib/icon/AppleIcon.svelte'
    import AndroidIcon from '$lib/icon/AndroidIcon.svelte'
    import LinuxIcon from '$lib/icon/LinuxIcon.svelte'
    import WindowsIcon from '$lib/icon/WindowsIcon.svelte'
    import MobileIcon from '$lib/icon/MobileIcon.svelte'
    import TabletIcon from '$lib/icon/TabletIcon.svelte'

    let { onclick = () => {} } = $props()

    const deviceInfo = getDeviceInfo(global?.data?.remoteDevice?.userAgent)
</script>

<div class="bg-[#ebebeb] dark:bg-[#252525]" data-test="init-device-info">
    <div
        class="container mx-auto flex max-w-md flex-col justify-start px-3 py-4"
    >
        <span class="text-sm"
            >{$_('You are authorizing the following device')}</span
        >
        <div class="flex items-end justify-between">
            <div class="mt-1 flex items-center">
                <span
                    class="relative -mt-1 inline-flex h-12 w-12 items-center justify-center"
                >
                    {#if deviceInfo.isApple}
                        <span class="absolute">
                            <AppleIcon />
                        </span>
                    {:else if deviceInfo.isAndroid}
                        <span class="absolute">
                            <AndroidIcon />
                        </span>
                    {:else if deviceInfo.isLinux}
                        <span class="absolute">
                            <LinuxIcon />
                        </span>
                    {:else if deviceInfo.isWindows}
                        <span
                            class="absolute"
                            class:mb-2={deviceInfo.isTypeUnknown}
                        >
                            <WindowsIcon />
                        </span>
                    {/if}

                    {#if deviceInfo.isTypeMobile}
                        <MobileIcon />
                    {:else if deviceInfo.isTypeTablet}
                        <TabletIcon />
                    {:else}
                        <DesktopIcon />
                    {/if}
                </span>
                <div class="ml-4 mt-2 flex flex-col">
                    <span>{deviceInfo.browser} {deviceInfo.os}</span>
                    <span class="text-xs"
                        >near {global.data?.remoteDevice?.location?.City}, {global
                            .data?.remoteDevice?.location?.Country}</span
                    >
                </div>
            </div>
            <button
                data-test="device-not-me-btn"
                {onclick}
                class="text-sm hover:underline focus:underline"
                >{$_('This is not me')}</button
            >
        </div>
    </div>
</div>
