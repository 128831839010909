<script>
    import { onDestroy, onMount } from 'svelte'
    import { _, locale } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import { getDisplay } from '$utils/common.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { getWallet, trimEthAddress } from '$utils/ethereum.js'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import logins from '$svr/providers/logins.json'
    import tippy from 'sveltejs-tippy'
    import { IS_PROD, SLIDE_ANIM_MS } from '$src/constants.js'
    import {
        getConsent,
        postLinkEth,
        postLinkEthChallenge,
        postLinkProvider,
    } from '$utils/api.js'
    import LoginProviderGroup from '$lib/LoginProviderGroup.svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'
    import Dropdown from '$lib/Dropdown.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import VerifyPhone from '$lib/VerifyPhone.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import PhoneIcon from '$lib/icon/PhoneIcon.svelte'

    let continueWithEmail = $state(false)
    let continueWithPhone = $state(false)

    let ethereumProgressModal = $state(null) //Will hold extension values
    let ethereumProgressNotifs = $state([])

    let authCancelledAtRemoteClient = $state(false)
    let authCancelledAtInitClient = $state(false)
    let evtSource = $state(null)

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we already got 2 recoveries
        if (global.data.recovery?.length >= 2) return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        logPlausibleEvent({ u: '/wizard/recovery' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function verifyEmailSuccess() {
        continueWithEmail = false
        global.data = await getConsent()
        if (global.data.recovery?.length >= 2) return replaceRoute('/')
    }

    async function verifyPhoneSuccess() {
        continueWithPhone = false
        global.data = await getConsent()
        if (global.data.recovery?.length >= 2) return replaceRoute('/')
    }

    async function continueWithEthereumExtension() {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        continueEthExtensionSigning(address)
    }

    async function continueEthExtensionSigning(address) {
        let challenge, signature

        const res = await postLinkEth(address)
        logPlausibleEvent({
            u: `/start/link/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Message signed'),
                    type: 'success',
                    status: $_('Linking wallet'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_(`You've rejected the sign request`),
                    'error',
                )
            } else {
                notification.show(
                    $_('Something went wrong. Please try again later.'),
                    'error',
                )
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
        }

        await postLinkEthChallenge(body)
        notification.show(
            $_('{provider} {label} has been added', {
                values: {
                    provider: body.name,
                    label: trimEthAddress(address),
                },
            }),
            'success',
        )
        logPlausibleEvent({
            u: `/link/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        global.data = await getConsent()
        ethereumProgressModal = null
        ethereumProgressNotifs = []
    }

    const displayString = (account) => {
        let string = ''
        if (account.slug === 'ethereum') {
            string += account.wallet?.name || 'Ethereum'
        } else if (account.slug === 'email' || account.slug === 'phone') {
            // Do nothing
        } else {
            string += getDisplay(account.slug)
        }
        if (account.slug === 'email') {
            string += account.user_name
        } else if (account.slug === 'phone') {
            string += window.intlTelInputUtils.formatNumber(
                account.user_name,
                null,
                window.intlTelInputUtils.numberFormat.INTERNATIONAL,
            )
        } else if (account.user_name) {
            if (account.mastodonServer) {
                string += ` (@${account.user_name}@${account.mastodonServer})`
            } else {
                string += ` (${account.user_name})`
            }
        }
        return string
    }

    function nextPage() {
        if (global.data?.recovery?.length >= 2) {
            navigateTo('/')
        }
    }

    $effect(() => {
        if (
            !global.data?.isPersonalLoggedIn &&
            !global.data?.isManagedLoggedIn
        ) {
            nextPage()
        }
    })

    let linkedProviders = $derived(
        Array.isArray(global.data.preferred) &&
            Array.isArray(global.data.recovery)
            ? [...global.data.preferred, ...global.data.recovery]
            : [],
    )

    let ignoreProviders = $derived([
        ...new Set(linkedProviders.map((i) => i.slug)),
    ])

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'email',
            server,
        })
        window.location.href = redirect
    }

    async function handleManagedEmailSuccess() {
        global.data = await getConsent()
    }
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

<AuthorizeLayout
    heading="Requires 3 independent login providers<br /> to verify your identity"
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    <section class="container mx-auto max-w-md">
        <div
            data-test="login-providers"
            class="text-charcoal mt-2 w-full rounded-md bg-[#DCDCDC] p-3 dark:bg-[#505050] dark:text-[#D4D4D4] {$locale &&
            $locale.startsWith('ar')
                ? 'text-right'
                : 'text-left'}"
        >
            <label for="recovery-providers" class="text-lg font-bold"
                >Login Providers</label
            >
            <div id="recovery-providers" class="my-1 space-y-1">
                {#each linkedProviders as provider, index}
                    <div
                        data-test="login-provider-{index}"
                        class="my-1 flex w-full items-center gap-x-2 truncate pl-4"
                    >
                        {#if provider.slug === 'email'}
                            <MailIcon />
                        {:else if provider.slug === 'phone'}
                            <PhoneIcon />
                        {:else if provider.slug === 'ethereum'}
                            <img
                                src={provider.wallet?.icon ||
                                    `https://cdn.hello.coop/images/${provider.slug}.svg`}
                                alt={provider.wallet?.name}
                                class="w-4.5 max-h-[18px]"
                            />
                        {:else if provider.mastodonServer}
                            <!-- TBD Broken -- Defaulting to default mastodon logo -->
                            <!-- <img
								src="https://{provider.mastodonServer}/favicon.ico"
								alt="Mastodon"
								class="w-4.5 max-h-[18px]"
							/> -->
                            <img
                                src="https://cdn.hello.coop/images/mastodon.svg"
                                alt="Mastodon"
                                class="w-4.5 max-h-[18px]"
                            />
                        {:else}
                            <img
                                src="https://cdn.hello.coop/images/{provider.slug}.svg"
                                alt={provider.slug}
                                class="w-4.5 max-h-[18px] {[
                                    'apple',
                                    'twitter',
                                ].includes(provider.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
                                    ? 'dark:invert-20 invert'
                                    : ''}"
                            />
                        {/if}
                        <div class="truncate">
                            {#if provider.slug === 'ethereum'}
                                <span
                                    use:tippy={{
                                        content: provider.login_hint,
                                        placement: 'top',
                                    }}
                                >
                                    {displayString(provider)}
                                </span>
                            {:else}
                                <span>
                                    {displayString(provider)}
                                </span>
                            {/if}
                        </div>
                    </div>
                {:else}
                    <div class="font-medium italic ml-4">None linked yet</div>
                {/each}
            </div>
        </div>

        <p
            data-test="page-cta"
            class="mx-auto mb-6 mt-6 block text-center text-lg font-medium md:max-w-md"
        >
            Link {3 - linkedProviders.length} more login provider{3 -
                linkedProviders.length !==
            1
                ? 's'
                : ''}
        </p>

        <div class="mx-auto mt-6 max-w-md">
            <LoginProviderGroup
                prefix="Link"
                {ignoreProviders}
                ethereum={continueWithEthereumExtension}
                managedEmailSuccess={handleManagedEmailSuccess}
            >
                {#if !ignoreProviders.includes('email')}
                    <Dropdown
                        id="email-container"
                        expanded={continueWithEmail}
                        ariaLabel={$_('Add email')}
                        dataTest="add-email-btn"
                        onclick={() => {
                            continueWithEmail = !continueWithEmail
                            continueWithPhone = false
                        }}
                    >
                        <div
                            class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                        >
                            <MailIcon />
                            <span class="block text-left" aria-hidden="true">
                                {$_('Add email')}
                            </span>
                        </div>
                        {#if continueWithEmail}
                            <div
                                class="px-3 pb-3 pt-1"
                                transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >
                                <VerifyEmail success={verifyEmailSuccess} />
                                <section class="mt-5 space-y-3">
                                    {#each logins.filter((i) => i.claims.verified_email) as provider}
                                        <LoginProvider
                                            onclick={(e) =>
                                                continueWithProvider(
                                                    provider.slug,
                                                    e,
                                                )}
                                            {provider}
                                            prefix="Get email from"
                                        />
                                    {/each}
                                </section>
                            </div>
                        {/if}
                    </Dropdown>
                {/if}

                {#if !ignoreProviders.includes('phone')}
                    <Dropdown
                        expanded={continueWithPhone}
                        ariaLabel={$_('Add phone')}
                        onclick={() => {
                            continueWithPhone = !continueWithPhone
                            continueWithEmail = false
                        }}
                    >
                        <div
                            class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                        >
                            <PhoneIcon />
                            <span class="block text-left" aria-hidden="true">
                                {$_('Add phone')}
                            </span>
                        </div>

                        {#if continueWithPhone}
                            <div
                                class="px-3 pb-3 pt-1"
                                transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >
                                <VerifyPhone success={verifyPhoneSuccess} />
                            </div>
                        {/if}
                    </Dropdown>
                {/if}
            </LoginProviderGroup>
        </div>
    </section>
</AuthorizeLayout>
