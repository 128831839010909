<script>
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { dedupe } from '$utils/common'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'

    let {
        provider = '',
        label = '',
        selected = $bindable(''),
        expanded = $bindable(false),
        expandedKey = '',
        handleDropdown = () => {},
        continueWithProvider = () => {},
    } = $props()
</script>

<div data-test="{provider}-container" class="space-y-1">
    <label for={provider} class="text-sm font-medium">{label}</label>
    {#if global.data?.release?.update_scope}
        {@const key = provider + 's'}
        <div class="box-shadow mt-2 space-y-2 rounded-md p-2">
            <div class="relative flex items-center">
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each dedupe(global.data?.release?.[key]) as item, index (item.id)}
                            <li
                                data-test="{provider}-{index}"
                                class="flex w-full items-center"
                            >
                                <input
                                    id="{provider}-{item.id}"
                                    name={provider}
                                    type="radio"
                                    bind:group={selected}
                                    value={item}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                />
                                <label
                                    for="{provider}-{item.id}"
                                    class="ml-2.5 inline-flex w-full items-center truncate"
                                >
                                    <div
                                        class="flex w-full items-center justify-between"
                                    >
                                        <div class="flex items-center gap-x-2">
                                            <span aria-hidden="true">
                                                {item.username}
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <LoginProvider
                onclick={(e) => continueWithProvider(provider, null, e)}
                provider={{ slug: provider }}
                prefix="Add another"
            />
        </div>
    {:else}
        {@const key = provider + 's'}
        <Dropdown
            hasBackground={false}
            dataTest="{provider}-dropdown"
            id="{provider}-dropdown"
            {expanded}
            ariaLabel={selected.username}
            onclick={() => handleDropdown(expandedKey)}
        >
            <div class="flex h-12 w-full items-center justify-between px-2">
                <div
                    data-test="selected-{provider}"
                    class="flex items-center gap-x-3"
                >
                    <span aria-hidden="true">
                        {selected.username}
                    </span>
                </div>
            </div>

            {#if expanded}
                {@const items = Array.isArray(global.data?.release?.[key])
                    ? dedupe(global.data.release[key], 'id').filter(
                          (i) => i.username !== selected.username,
                      )
                    : []}
                <div
                    class="px-indent flex w-full flex-col rounded-md text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul
                        class="mt-0.5 {items.length
                            ? 'border-y'
                            : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                    >
                        {#each items as item (item.id)}
                            <li>
                                <button
                                    onclick={() => {
                                        selected = item
                                        expanded = false
                                    }}
                                    class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                                >
                                    <div class="flex items-center gap-x-3">
                                        <span>{item.username}</span>
                                    </div>
                                </button>
                            </li>
                        {/each}
                    </ul>

                    <div class="my-2">
                        <LoginProvider
                            onclick={(e) =>
                                continueWithProvider(provider, null, e)}
                            provider={{ slug: provider }}
                            prefix="Add another"
                        />
                    </div>
                </div>
            {/if}
        </Dropdown>
    {/if}
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }

    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
